export default {
  proxy_path: "https://8ucsq2rr73.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://wjxruvq8p6.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyDVZjTV9W6b88zZ1cSax2y9SVBUckHtjCo",
  main_company_term_id: "6",
  crm_base_url: "https://crm.testing.saas.forwoodsafety.com",
  forwood_id_url: "https://id.testing.saas.forwoodsafety.com?redirect_uri=https://mapreport.testing.saas.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.testing.saas.forwoodsafety.com",
  Auth: {
    userPoolId: "us-west-2_IN4qXIpGt",
    userPoolWebClientId: "12c0kjjh0crqttep8vv6kjsvi0",
    cookieStorage: {
      domain: ".testing.saas.forwoodsafety.com",
      secure: true
    }
  }
};
